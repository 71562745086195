<template>
  <v-card>
    <v-toolbar dense flat class="teal" dark>
      <v-toolbar-title>Remover status</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-title class="justify-center">
      Você deseja remover esse status?
    </v-card-title>
    <v-card-actions>
      <v-btn color="error" text @click="closeDialog()"> Cancelar </v-btn>
      <v-spacer></v-spacer>

      <v-btn color="success" text @click="deleteEvent()"> Sim </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditEvent",
  props: ["status"],

  data() {
    return {
      eventstatus: {
        id: ""
      }
    };
  },
  created() {
    this.eventstatus.id = this.status.id;
  },
  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async deleteEvent() {
      try {
        const response = await this.$http.delete(
          `/scheduling-status/${this.eventstatus.id}`
        );
        if (response) this.$toast.success("Situação excluida com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
        this.$emit("close-dialog");
      }
    }
  }
};
</script>

<style></style>
